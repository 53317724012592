import styled from 'styled-components'

import Form from 'gatsby-theme-atomic-design/src/atoms/Search/Form'

const CustomForm = styled(Form).attrs({
  type: 'white',
})`
`

export default CustomForm
